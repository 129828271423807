.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1, h2, h3, h4, h5, h6, span, p, button{
  color: #fff !important
}

.dilog_popup h2, .dilog_popup p{
  color: #000 !important
}

.signup_span{
  color: #fff !important
}

.sign_up label{
  color: #fff !important;
}

.sign_up button{
  color: #000 !important;
}

.sign_up input{
  color: #fff !important;
}

.dilog_popup button{
  background-color: rgb(252, 213, 53) !important;
}

svg{
  color: rgb(252, 213, 53) !important
}

.no_data_text, .no_data_text h2, .no_data_text span{
  color: #000 !important
}

body{
  background: rgb(26,26,26);
}

/* .MuiMenu-list{
  margin-top: -50px;
} */

/* .MuiFormLabel-root.Mui-focused {
  color: #fff !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button_swap {
  color: #000 !important;
}

@keyframes moveCar {
  0% {
    left: -70px; /* Start off-screen */
  }
  100% {
    left: 100%; /* Move to the right edge */
  }
}